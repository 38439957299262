import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import forEach from 'lodash/forEach'
import map from 'lodash/map'
import max from 'lodash/max'
import sum from 'lodash/sum'
import mapValues from 'lodash/mapValues'
import startCase from 'lodash/startCase'
import camelCase from 'lodash/camelCase'
import upperCase from 'lodash/upperCase'
import replace from 'lodash/replace'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import sortBy from 'lodash/sortBy'

// Base Page
import BasePage from '@/pages/Base'

// Config
import config from '@/configs'

// Services
import ShipmentListService from '@/services/Shipments/ShipmentList'


@Component({})
export default class ShipmentListIndex extends BasePage {
  constructor() {
    super()
  }

  // BaseURL
  baseUrl = config.api.baseURL

  // Search Shipment ID
  searchInput: string = ''
  searchTrackingCode: string = ''
  searchCustomerName: string = ''
  searchLoading: boolean = false

  //-
  idEdit: any = null

  // Table
  tableHeaders: object[] = [
    {
      text: 'Shipment ID',
      align: 'center',
      sortable: false,
      value: 'shipmentID'
    },
    {
      text: 'P/L',
      align: 'center',
      sortable: false,
      value: 'pl_code'
    },
    {
      text: 'BL',
      align: 'center',
      sortable: false,
      value: 'bl_number'
    },
    {
      text: 'Port of Loading',
      align: 'center',
      sortable: false,
      value: 'port_loading'
    },
    {
      text: 'Port of Destination',
      align: 'center',
      sortable: false,
      value: 'port_destination'
    },
    {
      text: 'Estimate Departure Date',
      align: 'center',
      sortable: false,
      value: 'departure_date'
    },
    {
      text: 'Estimate Arrival Date',
      align: 'center',
      sortable: false,
      value: 'arrival_date'
    },
    {
      text: 'Shipment Method',
      align: 'left',
      sortable: false,
      value: 'shipmentMethod'
    },
    {
      text: 'Shipment Status',
      align: 'left',
      sortable: false,
      value: 'shipment_status'
    },
    {
      text: 'Action',
      align: 'center',
      sortable: false,
      value: 'action',
      class: 'action-table-width'
    }
  ]

  //-Table Expand-//
  tableHeadersExpand: object[] = [
    {
      text: 'Packing List ID',
      align: 'center',
      sortable: false,
      class: 'table-cell-width'
    },
    {
      text: 'Order ID',
      align: 'center',
      sortable: false,
      class: 'table-cell-width'
    },
    {
      text: 'Customer Name',
      align: 'center',
      sortable: false,
      class: 'table-cell-width'
    },
    {
      text: 'Tracking Code',
      align: 'center',
      sortable: false,
      class: 'table-cell-width'
    },
    {
      text: 'Tracking Status',
      align: 'center',
      sortable: false,
      class: 'table-cell-width'
    },
    {
      text: 'Qty',
      align: 'center',
      sortable: false,
      class: 'table-cell-width'
    },
    {
      text: 'CBM',
      align: 'center',
      sortable: false,
      class: 'table-cell-width'
    },
    {
      text: 'Weight Kgs',
      align: 'center',
      sortable: false,
      class: 'table-cell-width'
    },
    {
      text: 'Action',
      align: 'center',
      sortable: false,
      value: 'action',
      class: 'action-table-width'
    }
  ]

  //-Table Status-//
  tableHeadersStatus: object[] = [
    {
      text: 'No.',
      align: 'center',
      sortable: false,
      class: 'table-cell-width'
    },
    {
      text: 'Status',
      align: 'left',
      sortable: false,
      class: 'table-cell-width'
    },
    {
      text: 'Date',
      align: 'center',
      sortable: false,
      class: 'table-cell-width'
    },
    {
      text: 'Action',
      align: 'center',
      sortable: false,
      value: 'action',
      class: 'action-table-width'
    }
  ]

  //-status-//
  inputStatus: any = {
    items: [],
    keyword: null,
    loading: false,
    selected: null,
    trackingStatusDate: {
      open: false,
      date: null,
      dateFormatted: null,
      tempDate: null
    },
    trackingStatusTime: {
      open: false,
      time: null,
      // timeFormatted: null,
      tempTime: null
    }
  }

  //-Modal Edit Shipment-//
  editShipmentList: any = {
    open: false
  }

  //-Modal Detail Status-//
  detailStatusShipment: any = {
    open: false
  }

  tableItems: object[] = []
  shipmentStatusItems: object[] = []
  // tableItemsExpand: object[] = []
  tableItemsSearch: object[] = []
  tableTotalItems: string | number = 0
  tableTotalItemsExpand: string | number = 0
  tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
  tablePagination: any = {
    sortBy: '-created_at',
    page: 1,
    rowsPerPage: 10,
    descending: true,
    totalItems: 0
  }

  tableLoading: boolean = true
  tableLoadingExpand: boolean = false

  // Delete Shipment Request
  deleteDialog: boolean = false
  deleteShipment: any = {
    modalOpen: false,
    id: 0,
    bl_number:''
  }

  @Watch('searchInput')
  @Watch('searchTrackingCode')
  @Watch('searchCustomerName')
  @Watch('tablePagination', { deep: true })
  onTablePaginationChanged() {
    this.$vuetify.goTo(0)
    this.getShipmentList()
  }

  mounted() {
    this.getShipmentList()
    this.getStatus()
  }

  getShipmentList(): any {
    const opts: any = {
      params: {
        'page[num]': this.tablePagination.page,
        'page[limit]': this.tablePagination.rowsPerPage,
        'sort': this.tablePagination.sortBy,
        include: 'packages,orders'
      }
    }
    if (this.searchInput != '') {
      opts.params = {
        ...opts.params,
        'filter[id][like]': this.searchInput
      }
    } else {
      var textFile = 'filter[id][like]'
      delete opts.params[textFile]
    }

    if (this.searchTrackingCode != '') {
      opts.params = {
        ...opts.params,
        'filter[packages.tracking_code][like]': this.searchTrackingCode
      }
    } else {
      var textFile = 'filter[packages.tracking_code][like]'
      delete opts.params[textFile]
    }

    if (this.searchCustomerName != '') {
      opts.params = {
        ...opts.params,
        'filter[orders.customer_name][like]': this.searchCustomerName
      }
    } else {
      var textFile = 'filter[orders.customer_name][like]'
      delete opts.params[textFile]
    }

    this.tableLoading = true
    ShipmentListService.getShipmentListIndex(opts)
      .then(response => {
        const responseData = response.data.data
        const responseDataIncluded = response.data.included
        const responseMeta = response.data.meta
        this.tableItems = []
        forEach(responseData, dataShipment => {
          let shipment = {
            id: dataShipment.id == null ? '-' : dataShipment.id,
            shipmentId: dataShipment.attributes.id == '' ? '-' : dataShipment.attributes.id,
            pl: dataShipment.attributes['P/L'] == null ? '-' : dataShipment.attributes['P/L'],
            bl_number: dataShipment.attributes.bl_number == '' ? '-' : dataShipment.attributes.bl_number,
            from: dataShipment.attributes.from == '' ? '-' : dataShipment.attributes.from,
            to: dataShipment.attributes.to == '' ? '-' : dataShipment.attributes.to,
            departure_date: dataShipment.attributes.departure_date == '' ? '-' : dataShipment.attributes.departure_date,
            arrival_date: dataShipment.attributes.arrival_date == '' ? '-' : dataShipment.attributes.arrival_date,
            shipmentMethod: dataShipment.attributes.shipment_method == '' ? '-' : upperCase(replace(dataShipment.attributes.shipment_method, '_', ' ')),
            shipmentStatus: dataShipment.attributes.last_status == null ? '-' : startCase(dataShipment.attributes.last_status.name),
            shipmentStatusTime: dataShipment.attributes.last_status == null ? null : dataShipment.attributes.last_status.pivot.date,
            shipmentStatusId: dataShipment.attributes.last_status == null ? '-' : startCase(dataShipment.attributes.last_status.id),
            actionFab: false,
            packingData: []
          }
          for (const item_package of dataShipment.relationships.packages){
            const packages_data = {
              ...responseDataIncluded.packages[Number(item_package.id)].attributes,
              order_code: null,
              dimensions: {
                qty : 0,
                cbm : 0,
                weight_kgs : 0
              },
              link_tracking : ''
            }

            if (process.env.VUE_APP_API_URL === 'https://api2dev.asiacommerce.net/') {
              packages_data.link_tracking = 'http://alidev.asiacommerce.net/tracking/' + packages_data.tracking_code
            }
            else if (process.env.VUE_APP_API_URL === 'https://api2.asiacommerce.net/'){
              packages_data.link_tracking = 'http://ali.asiacommerce.net/tracking/' + packages_data.tracking_code
            }

            const opts: any = {
              params: {
                include: 'packaging,newstatus,order',
                'filter[id][in]': item_package.id
              }
            }
            ShipmentListService.getPackingList(opts)
              .then(response => {
                forEach(response.data.included.packaging, packaging => {
                  var dimens = 0
                  if(packaging.attributes.dimension_unit === 'meters'){
                    dimens = packaging.attributes.dimensions[0].value * packaging.attributes.dimensions[1].value * packaging.attributes.dimensions[2].value
                  }else if(packaging.attributes.dimension_unit === 'inchs'){
                    dimens = (packaging.attributes.dimensions[0].value/39.37)*(packaging.attributes.dimensions[1].value/39.37)*(packaging.attributes.dimensions[2].value/39.37)
                  }else if(packaging.attributes.dimension_unit === 'cms'){
                    dimens = (packaging.attributes.dimensions[0].value/100)*(packaging.attributes.dimensions[1].value/100)*(packaging.attributes.dimensions[2].value/100)
                  }
                  packages_data.dimensions.qty = packages_data.dimensions.qty + packaging.attributes.package_qty
                  packages_data.dimensions.cbm = packages_data.dimensions.cbm + dimens
                  packages_data.dimensions.weight_kgs = packages_data.dimensions.weight_kgs + packaging.attributes.gross_weight
                })
                
                const array_order = response.data.data[0].relationships.order.id
                packages_data.customer_name = response.data.included.order[array_order].attributes.customer_name
                packages_data.order_code = response.data.included.order[array_order].attributes.order_code 
              })

            shipment.packingData.push(packages_data)
          }

          this.tableItems.push(shipment)
        })
        // console.log(this.tableItems)
        this.tableTotalItems = responseMeta.pagination.total
        this.tableLoading = false
      })
      .catch((error: any) => {
        this.tableLoading = false
        this.catchHandler(error)
      })
  }

  async getStatus() {
    try {
      this.inputStatus.loading = true
      this.inputStatus.items = []
      const opts: any = {
        params: {
          'page[num]': 1,
          'page[limit]': 10,
          sort: '-created_at',
          'filter[status_type][is]': 5
        }
      }
      const response = await ShipmentListService.getStatus(opts)
      const responseDataStatus = response.data.data
      for (const status of responseDataStatus) {
        status.attributes.name = startCase(status.attributes.name)
        this.inputStatus.items.push(status.attributes)
      }
      this.inputStatus.items = sortBy(this.inputStatus.items, 'id')
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.inputStatus.loading = true
    }
  }

  searchFilterChanged(newData: any) {
    this.tablePagination.page = 1
    this.searchFilterParams = newData
    this.getShipmentList()
  }

  onEditShipmentModalOpen(index, idstatus) {
    this.editShipmentList.open = true
    this.inputStatus.selected = find(this.inputStatus.items, status => status.id == idstatus) == undefined ? null : find(this.inputStatus.items, status => status.id == idstatus).id
    this.idEdit = index
  }

  onEditShipmentClosed() {
    this.editShipmentList.open = false
    this.inputStatus.trackingStatusDate = {
      open: false,
      date: null,
      dateFormatted: null,
      tempDate: null
    }
    this.inputStatus.trackingStatusTime= {
      open: false,
      time: null,
      // timeFormatted: null,
      tempTime: null
    }
    this.$validator.errors.clear()
    this.$validator.reset()
  }

  deletedShipmentList(id, bl_number){
    this.deleteShipment.modalOpen = true
    this.deleteShipment.id = id
    this.deleteShipment.bl_number = bl_number
  }
  async onDeletedShipmentItem(){
    this.showLoading({ text: 'Loading...' })
    try{
      const response = await ShipmentListService.deleteShipment(this.deleteShipment.id)
      var message = response.data.message
      this.getShipmentList()

    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.deleteShipment.modalOpen = false
      this.showSnackbar({
        text: message,
        color: 'info',
        timeout: 2500
      })
      this.closeLoading()
    }
  }

  async onEditShipmentSave() {
    try {
      const valid = await this.$validator.validateAll('editStatus')
      if (valid) {
        const payload = {
          status_id: this.inputStatus.selected,
          date: this.inputStatus.trackingStatusDate.date + ' ' + this.inputStatus.trackingStatusTime.time + ':00'
        }
        const response = await ShipmentListService.changeStatus(payload, this.idEdit)
        if (response.data.success) {
          this.showSnackbar({
            text: 'Successfully Change Status',
            color: 'green',
            timeout: 1500
          })
          this.getShipmentList()
          this.onEditShipmentClosed()
        } else {
          this.showSnackbar({
            text: 'Change Status Failed',
            color: 'red',
            timeout: 1500
          })
        }
      }else{
        this.showSnackbar({
          text: 'Please check all fields requirements',
          color: 'red',
          timeout: 2000
        })
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
    }
  }

  onTrackingStatusDateCancel() {
    this.inputStatus.trackingStatusDate.open = false
  }

  onTrackingStatusDateDone() {
    // this.inputStatus.trackingStatusDate.date = this.inputStatus.trackingStatusDate.tempDate
    this.inputStatus.trackingStatusDate.dateFormatted = this.toFullDate(
      this.inputStatus.trackingStatusDate.date
    )
    this.inputStatus.trackingStatusDate.open = false
  }
  
  // onTrackingStatusTimeInput(event) {
  // 	this.inputStatus.trackingStatusTime.tempTime = event
  // }

  onTrackingStatusTimeCancel() {
    this.inputStatus.trackingStatusTime.open = false
  }

  onTrackingStatusTimeDone() {
    // this.inputStatus.trackingStatusTime.time = this.inputStatus.trackingStatusDate.tempTime
    // this.inputStatus.trackingStatusTime.timeFormatted = this.inputStatus.tempTime
    this.inputStatus.trackingStatusTime.open = false
  }

  onDetailStatusShipmentOpen(shipmentId) {
    ShipmentListService.getShipmentListDetail(shipmentId)
      .then(response => {
        this.shipmentStatusItems = []
        forEach(response.data.included.status, status => {
          this.shipmentStatusItems.push(status.attributes)  
        })
      })
    this.detailStatusShipment.open = true
    this.idEdit = shipmentId
  }
  
  async deletedShipmentStatus(id){
    const payload = {
      detach: id
    }

    const response = await ShipmentListService.changeStatus(payload, this.idEdit)
    if (response.data.success) {
      this.showSnackbar({
        text: 'Successfully Delete Shipment Status',
        color: 'green',
        timeout: 1500
      })

      ShipmentListService.getShipmentListDetail(this.idEdit)
        .then(response => {
          this.shipmentStatusItems = []
          forEach(response.data.included.status, status => {
            this.shipmentStatusItems.push(status.attributes)  
          })
        })

      // const dataTable = findIndex(this.tableItems, item => item['shipmentId'] == this.idEdit)
      // if(!isEmpty(this.shipmentStatusItems)){
      //   console.log('this.shipmentStatusItems[this.shipmentStatusItems.length - 1]', this.shipmentStatusItems[this.shipmentStatusItems.length - 1])
      //   this.tableItems[dataTable]['shipmentStatus'] = this.shipmentStatusItems[this.shipmentStatusItems.length - 1]['name']
      //   this.tableItems[dataTable]['shipmentStatusTime'] = this.shipmentStatusItems[this.shipmentStatusItems.length - 1]['pivot'].date
      //   this.tableItems[dataTable]['shipmentStatusId'] = this.shipmentStatusItems[this.shipmentStatusItems.length - 1]['id']
      // }else{
      //   this.tableItems[dataTable]['shipmentStatus'] = '-'
      //   this.tableItems[dataTable]['shipmentStatusTime'] = null
      //   this.tableItems[dataTable]['shipmentStatusId'] = 0
      // }
      this.getShipmentList()
      this.detailStatusShipment.open = false
    } else {
      this.showSnackbar({
        text: 'Delete Shipment Status Failed',
        color: 'red',
        timeout: 1500
      })
    }
  }
}
